<template>
    <div class="o365-body-center-viewport" :ref="setViewportContainer" @scroll="handleScroll">
        <slot name="overlay"></slot>

        <div v-if="gridControl.columns.hasPinnedLeftColumns" class="o365-body-left-pinned-cols" :style="[
            { 'min-width': gridControl.columns.leftPinnedWidth + 'px' },
            { 'max-width': gridControl.columns.leftPinnedWidth + 'px' },
            { 'width': gridControl.columns.leftPinnedWidth + 'px' },
            { 'height': disableSetHeight ? null : (dataLength * rowHeight) + 'px' }
        ]">
            <template v-for="(item, itemIndex) in listData" :key="itemIndex">
                <slot name="left" :row="item" :rowIndex="itemIndex">
                </slot>
            </template>
        </div>


        <div class="o365-body-center-cols" :style="{ 'height': disableSetHeight ? null : (dataLength * rowHeight) + 'px' }">
            <div class="o365-body-center-cols-viewport" style="height: calc(100% + 17px);">
                <div class="o365-body-center-cols-container" :style="[{ 'width': gridControl.columns.centerWidth + 'px' }]">
                    <template v-for="(item, itemIndex) in listData" :key="itemIndex">
                        <slot name="center" :row="item" :rowIndex="itemIndex">
                        </slot>
                    </template>
                </div>
            </div>
        </div>


        <div v-if="gridControl.columns.hasPinnedRightColumns" class="o365-body-right-pinned-cols" :style="[
            { 'min-width': gridControl.columns.rightPinnedWidth + 'px' },
            { 'max-width': gridControl.columns.rightPinnedWidth + 'px' },
            { 'width': gridControl.columns.rightPinnedWidth + 'px' },
            { 'height': disableSetHeight ? null : (dataLength * rowHeight) + 'px' }
        ]">
            <template v-for="(item, itemIndex) in listData" :key="itemIndex">
                <slot name="right" :row="item" :rowIndex="itemIndex">
                </slot>
            </template>
        </div>

        <slot name="misc"></slot>
    </div>
</template>

<script lang="ts">
export interface IBaseListProps {
    /** Source data to display */
    data: any[];
    /** Disable height setting for column containers */
    disableSetHeight?: boolean;
    /** Row height */
    rowHeight?: number;
    /** Override for the data length */
    dataLength?: number;
    /** Sets this list as the main viewport for the grid */
    setViewportContainer?: boolean;
    /** Will not run any composables for the provided data */
    disableDataTransformations?: boolean;
    /** When provided if setViewportContainer is false will use this function instead */
    getViewportContainer?: (el:HTMLElement) => void;
    /** Will not use the grid scroll handler */
    disableScrollHandler?: boolean;
}
</script>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import { computed, toRef } from 'vue';

const props = withDefaults(defineProps<IBaseListProps>(), {
    rowHeight: 34,
});


const gridControl = useBaseGridControl();

let listData = null;
if (props.disableDataTransformations) {
    listData = toRef(props, 'data');
} else if (gridControl.value.props.dataTransformComposable) {
    listData = await gridControl.value.props.dataTransformComposable(toRef(props, 'data'), gridControl);
} else {
    const ArrayDataComposable = await import('o365.vue.composables.Grid.ArrayData.ts');
    listData = await ArrayDataComposable.useArrayData(toRef(props, 'data'), gridControl);
}

const dataLength = computed(() => {
    return props.dataLength ?? gridControl.value?.arrayData?.dataLength;
});

function setViewportContainer(el: HTMLElement) {
    if (props.setViewportContainer) {
        gridControl.value.viewport = el;
    } else if (props.getViewportContainer) {
        props.getViewportContainer(el);
    }
}

function handleScroll(event: MouseEvent) {
    if (!props.disableScrollHandler) {
        gridControl.value.handleViewportVerticalScroll(event)
    }
}
</script>